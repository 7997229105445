<template>
  <header class="main-header-div">
    <div class="header u-unselectable header-animated">
        <div class="header-brand">
            <div class="nav-item no-hover">
                <router-link to="/">
                  <img class="logo" alt="Femmes Initiative Logo" src="../assets/images/logo.png" />
                </router-link>
            </div>
            <div class="nav-item nav-btn" id="header-btn">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div class="header-nav" id="header-menu">
            <div class="nav-right">
                <div class="nav-item has-sub toggle-hover" id="associationDropdown">
                  <a class="nav-dropdown-link">L'Association</a>
                  <ul class="dropdown-menu dropdown-animated" role="menu">
                      <li class="menu-link" role="menu-item"><router-link to="/notre-histoire">Notre histoire</router-link></li>
                      <li class="menu-link" role="menu-item"><router-link to="/notre-equipe">Notre équipe</router-link></li>
                      <li class="menu-link" role="menu-item"><router-link to="/nos-partenaires">Nos partenaires</router-link></li>
                  </ul>
                </div>
                <div class="nav-item has-sub toggle-hover" id="formationsDropdown">
                  <a class="nav-dropdown-link">Nos formations</a>
                  <ul class="dropdown-menu dropdown-animated" role="menu">
                      <li class="menu-link" role="menu-item"><router-link to="/nos-cours-de-francais">Cours de français</router-link></li>
                      <li class="menu-link" role="menu-item"><router-link to="/nos-cours-d-alphabetisation">Alphabétisation</router-link></li>
                      <li class="menu-link" role="menu-item"><router-link to="/nos-cours-de-francais-sur-mesure">Cours de français sur mesure</router-link></li>
                  </ul>
                </div>
                <div class="nav-item has-sub toggle-hover" id="apprendreDropdown">
                  <a class="nav-dropdown-link">Le français autrement</a>
                  <ul class="dropdown-menu dropdown-animated" role="menu">
                      <li class="menu-link" role="menu-item"><router-link to="/nos-cours-ouvert-a-tous">Ouvert à tous</router-link></li>
                      <li class="menu-link" role="menu-item"><router-link to="/nos-cours-reserve-aux-apprenants">Réservé aux apprenants</router-link></li>
                  </ul>
                </div>
                <div class="nav-item menu-link">
                    <router-link to="/nos-accompagnements">Nos accompagnements</router-link>
                </div>
                <div class="nav-item menu-link">
                    <router-link to="/soutenez-nous">Soutenez-nous</router-link>
                </div>
                <div class="nav-item menu-link">
                    <router-link to="/contact">Contact</router-link>
                </div>
            </div>
        </div>
    </div>
  </header>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from 'vue';

  export default defineComponent({
    setup() {
      onMounted(() => {
          // Get all the nav-btns in the page
        const navBtns = document.querySelectorAll('.nav-btn');

        // Add an event handler for all nav-btns to enable the dropdown functionality
        navBtns.forEach((ele) => {
          ele.addEventListener('click', () => {
            // Get the dropdown-menu associated with this nav button (insert the id of your menu)
            const dropDownMenu = document.getElementById('header-menu');
            // Toggle the nav-btn and the dropdown menu
            ele.classList.toggle('active');
            dropDownMenu.classList.toggle('active');
          });
        });

        document.querySelectorAll('.menu-link').forEach((element) => {
          element.addEventListener('click', () => {
            console.log('i click');
            document.getElementById('header-menu').classList.remove('active');
          });
        });
      });
    },
  });
</script>

<style lang="scss" scoped>
  .main-header-div {
    display: grid;
    justify-items: center;
    background: linear-gradient(rgba(0,255,0,0.06), white);
  }

  .header {
    height: 100%;
    max-width: 1200px;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    // border-bottom: .05rem solid rgba(173,181,189,.5)
  }
  .nav-item {
    font-size: 16px;
    a {
      font-weight: bold;
    }
  }
  .logo {
    width: 86px;
    margin-left: -20px;
  }
</style>
