<template>
  <div class="bottom-footer u-text-center py-4">
    <div class="template">
      <div class="template__content">
        <div class="row pb-2">
          <div class="col-12">
            <img class="logo" alt="Femmes Initiative Logo" src="../assets/images/logo.png" />
            <h6>Femmes Initiatives</h6>
          </div>
          <div class="col-12">7 rue Lachelier, 75013 Paris</div>
        </div>

        <div class="annonce-message">
          Nos locaux ne bénéficient pas d'un accès aux personnes handicapées et PMR
        </div>

        <div class="row links">
          <div class="col-4">
            <router-link to="/notre-equipe">Notre équipe</router-link>
          </div>
          <div class="col-4">
            <router-link to="/nos-cours-de-francais">Nos Formations</router-link>
          </div>
          <div class="col-4">
            <a
              target="_blank"
              href="https://fr-fr.facebook.com/pages/category/Nonprofit-Organization/Femmes-Initiatives-1639681316351534/"
              >Suivez Nous Sur Facebook</a
            >
          </div>
          <div class="col-3">
            <a target="_blank" href="/chartre.pdf" download>La chartre engagement</a>
          </div>
          <div class="col-3">
            <router-link to="/mentions-legales">Mentions légales</router-link>
          </div>
          <div class="col-3">
            <router-link to="/politique-de-confidentialite"
              >Politique de confidentialité</router-link
            >
          </div>
          <div class="col-3"><router-link to="/plan-du-site">Plan du site</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.bottom-footer {
  font-size: 12px;
  background-color: #eee;

  .template .template__content {
    min-height: initial;
  }
  .logo {
    width: 75px;
  }
  .links {
    width: 75%;
    margin-left: 12.5%;
    .col-4 {
      padding: 0.5rem 0;
    }
  }

  .annonce-message {
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

a {
  color: #444;
}
</style>
