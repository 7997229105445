<template>
  <div>
    <Menu />
    <section class="section mt-4">
      <router-view />
    </section>
    <Footer />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Menu,
    Footer,
  },
};
</script>

<style lang="scss" scoped>

</style>
