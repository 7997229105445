import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: () => import(/* webpackChunkName: "Accueil" */ '../views/Accueil.vue'),
  },
  {
    path: '/notre-histoire',
    name: 'Histoire',
    component: () => import(/* webpackChunkName: "Histoire" */ '../views/Histoire.vue'),
  },
  {
    path: '/notre-equipe',
    name: 'Equipe',
    component: () => import(/* webpackChunkName: "Equipe" */ '../views/Equipe.vue'),
  },
  {
    path: '/nos-partenaires',
    name: 'Partenaires',
    component: () => import(/* webpackChunkName: "Partenaires" */ '../views/Partenaires.vue'),
  },
  {
    path: '/nos-cours-de-francais',
    name: 'CoursDeFrancais',
    component: () => import(/* webpackChunkName: "CoursDeFrancais" */ '../views/CoursDeFrancais.vue'),
  },
  {
    path: '/nos-cours-d-alphabetisation',
    name: 'Alphabetisation',
    component: () => import(/* webpackChunkName: "Alphabetisation" */ '../views/Alphabetisation.vue'),
  },
  {
    path: '/nos-cours-de-francais-sur-mesure',
    name: 'CoursFrancaisSpe',
    component: () => import(/* webpackChunkName: "CoursFrancaisSpe" */ '../views/CoursFrancaisSpe.vue'),
  },
  {
    path: '/nos-cours-ouvert-a-tous',
    name: 'Ouvert',
    component: () => import(/* webpackChunkName: "Ouvert" */ '../views/Ouvert.vue'),
  },
  {
    path: '/nos-cours-reserve-aux-apprenants',
    name: 'Reserve',
    component: () => import(/* webpackChunkName: "Reserve" */ '../views/Reserve.vue'),
  },
  {
    path: '/nos-accompagnements',
    name: 'Accompagnement',
    component: () => import(/* webpackChunkName: "Accompagnement" */ '../views/Accompagnement.vue'),
  },
  {
    path: '/soutenez-nous',
    name: 'SoutenezNous',
    component: () => import(/* webpackChunkName: "SoutenezNous" */ '../views/SoutenezNous.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
  },
  {
    path: '/inscription',
    name: 'Inscription',
    component: () => import(/* webpackChunkName: "Inscription" */ '../views/Inscription.vue'),
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: () => import(/* webpackChunkName: "MentionsLegales" */ '../views/MentionsLegales.vue'),
  },
  {
    path: '/plan-du-site',
    name: 'PlanDuSite',
    component: () => import(/* webpackChunkName: "PlanDuSite" */ '../views/PlanDuSite.vue'),
  },
  {
    path: '/politique-de-confidentialite',
    name: 'Confidentialite',
    component: () => import(/* webpackChunkName: "Confidentialite" */ '../views/Confidentialite.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
